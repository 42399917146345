import React from "react";
import IconButton from "components/IconButton";
import Button from "components/Button";
import { useActions } from "state/hooks";
import { loginSocial } from "state/actions/auth";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

export default function SocialLogin(props) {
    const { onLogin } = useActions({
        onLogin: loginSocial,
    });

    return (
        <div className={"rows gap-xs rows-center"}>
            <div className={"type-sm o-50"}>
                or continue with social profile
            </div>
            <div className={"cols cols-center"}>
                {/*<IconButton onClick={()=>onLogin('github')} icon={'github'}/>*/}
                <Button
                    variant={"outlined"}
                    onClick={() => onLogin("google-oauth2")}
                    startIcon={<Icon>google</Icon>}
                >
                    Sign in with Google
                </Button>
            </div>
        </div>
    );
}

SocialLogin.propTypes = {};
SocialLogin.defaultProps = {};
