import {change} from "state/actions/data";
import {useCallback, useMemo} from "react";
import {useData, useActions} from "state/hooks";

export function useField(type, id, field) {
    const value = useData({type, id});
    const {onChange} = useActions({onChange: change});
    const handleChange = useCallback(value => {
        let next = {};
        next[field] = value;
        onChange(type, id, next);
    }, [type, id, field, onChange]);
    const fieldValue = value[field];

    return useMemo(() => ({
        value: fieldValue,
        onChange: handleChange
    }), [fieldValue, handleChange]);
}