import {useSelector} from "react-redux";
import {selectList} from "state/selectors/lists";

export function useList(type, id) {

    const items = useSelector(s=>selectList(s, type, id));
    return {
        items
    };

}
