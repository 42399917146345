import uuid from 'uuid/v4';

export const reset = (type, id) => {
    return {
        type: 'DATA.RESET',
        payload: { type, id }
    }
};

export const resetBatch = (paths) => {
    return {
        type: 'DATA.RESET.BATCH',
        payload: { paths }
    }
};

export const change = (type, id, data, validate) => {
    return {
        type: 'DATA.CHANGE',
        payload: { type, id, data, validate }
    }
};

export const storeOne = (type, id, data) => {
    return {
        type: 'DATA.STORE.ONE',
        payload: { type, id, data }
    }
};

export const patchOne = (type, id, data) => ({
    type: 'DATA.PATCH',
    payload: { type, id, data }
});

export const reject = (type, id) => {
    return {
        type: 'DATA.REJECT',
        payload: { type, id }
    }
};

export const rejectRequest = (type, id) => {
    return {
        type: 'DATA.REJECT.REQUEST',
        payload: { type, id }
    }
};

export const storeAlias = (type, alias, id) => {
    return {
        type: 'DATA.STORE.ALIAS',
        payload: { type, alias, id }
    }
};

export const storeList = (type, list, data, meta, append, yieldData, position) => {
    return {
        type: 'DATA.STORE.LIST',
        payload: {type, list, data, meta, append, yieldData, position}
    }
};

export const storeListIds = (type, list, data) => {
    return {
        type: 'DATA.STORE.LIST_IDS',
        payload: {type, list, data}
    }
};

export const storeBatch = (data, meta) => {
    return {
        type: 'DATA.STORE.BATCH',
        payload: {data, meta}
    }
};

export const resetList = (type, list, filter) => {
    return {
        type: 'DATA.LIST.RESET',
        payload: {type, list, filter}
    }
};

export const resetAll = () => {
    return {
        type: 'DATA.RESET_ALL'
    }
};

export const create = (type, item, list, position) => {
    if (!item.id) {
        item.id = uuid();
    }
    if (!list) {
        return {
            type: 'DATA.CREATE',
            payload: { type, item, position }
        }
    }
    return {
        type: 'DATA.LIST.CREATE',
        payload: { type, list, item, position }
    }
};

export const pathChange = (path, value) => {
    return {
        type: 'DATA.PATH.CHANGE',
        payload: {
            path,
            value
        }
    }
};

export const pathAppend = (path, value) => {
    return {
        type: 'DATA.PATH.APPEND',
        payload: {
            path,
            value
        }
    }
};

export const pathDetach = (path, value) => {
    return {
        type: 'DATA.PATH.DETACH',
        payload: {
            path,
            value
        }
    }
};

export const pathUnset = (path) => {
    return {
        type: "DATA.PATH.UNSET",
        payload: {
            path
        }
    }
};

export const dataListDelete = (type, id, list) => ({
    type: 'DATA.LIST.DELETE',
    payload: {type, id: list, item: id}
});

export const validate = (type, id) => ({
    type: 'DATA.VALIDATE',
    payload: {type, id}
});