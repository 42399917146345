import React from 'react';
//import PropTypes from 'prop-types';
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";

const classes = {
    root: 'input-dense'
};

const classesEmpty = {
    root: 'input-dense',
    input: 'value-placeholder'
};

export default class TextDense extends React.Component {

    handleChange = (evt) => {
        this.props.onChange(evt.target.value);
    };

    render() {
        const {
            label,
            value,
            autoFocus,
            onBlur,
            addonComponent
        } = this.props;

        return (<FormControl style={{ width: '100%' }}>
            { value ? <div className={'input-dense__label'}>{label}</div> : null }
            <InputBase
                onChange={this.handleChange}
                value={value}
                placeholder={label}
                fullWidth={true}
                type="text"
                autoFocus={autoFocus}
                onBlur={onBlur}
                classes={value ? classes : classesEmpty}
                endAdornment={addonComponent}
            />
        </FormControl>);
    }

}

TextDense.propTypes = {};