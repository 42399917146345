import {useMemo} from "react";
import {useDispatch} from "react-redux";

export function useActions(actionsMap) {
    const actions = useMemo(() => {
        return actionsMap;
        // eslint-disable-next-line
    }, Object.values(actionsMap));

    const dispatch = useDispatch();

    return useMemo(() => {
        const boundActions = {};
        for (let actionName in actions) {
            let action = actions[actionName];
            boundActions[actionName] = (...args) => {
                dispatch(action(...args));
            }
        }
        return boundActions;
    }, [actions, dispatch]);
}