import React from "react";
import { connect } from "react-redux";
import cn from "classnames";
import CollectionContainer from "components/fields/collection/CollectionContainer";
import { withData } from "containers/Entity";
import { ArrayIterator } from "components/list/iterator";
import { pushRouteParams } from "state/actions/router";
import List from "containers/List";
import FilePreview from "components/file/FilePreview";
import UploadButton from "components/upload/UploadButton";
import IconButton from "components/IconButton";
import { handleUploadCallback } from "state/actions/upload";
import { withApiContext, bindApiActions } from "containers/context/ApiContext";
//import {selectProgress} from "state/selectors/ui";
import Preloader from "components/Preloader";
import Icon from "components/Icon";
import { openMediaSelect } from "state/actions/media";
import { useMedia } from "containers/media";

const mapStateToProps = (store, props) => {
    return {
        path: [props.entityType, props.entityId, props.id].join("/"),
        mediaType:
            props.entityType.indexOf("cms.") === 0 ? "cms.media" : "db.media",
    };
};

const mapDispatchToProps = bindApiActions(
    {
        onUpload: handleUploadCallback,
    },
    {
        pushQuery: pushRouteParams,
        onOpenLibrary: openMediaSelect,
    }
);

export function Media(props) {
    const {
        isPending,
        id,
        file,
        //secure_url,
        itemType,
        type,
        onClick,
        onDelete,
        readOnly,
    } = props;

    const handleClick = () => onClick(id);

    const remove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete(id);
    };

    const { Render } = useMedia();
    const RenderFilePreview = Render || FilePreview;

    return (
        <div className={"media-file relative"} onClick={handleClick}>
            <RenderFilePreview
                file={file}
                mediaType={type}
                type={itemType}
                id={id}
            />
            {onDelete && !readOnly ? (
                <div onClick={remove} className={"media-delete"}>
                    <Icon>mui-close</Icon>
                </div>
            ) : null}
            <Preloader
                visible={isPending}
                thickness={2}
                size={16}
                color={"inherit"}
            />
        </div>
    );
}

Media.autoFillProps = ["file", "type", "secure_url"];

export const MediaIterator = ArrayIterator(withData(Media));

export function MediaGrid(props) {
    const {
        type,
        items,
        onUpload,
        onOpenLibrary,
        readOnly,
        maxItems,
        variant,
    } = props;

    let itemsCount = items ? items.length : 0;
    const canAddMore = !readOnly && (!maxItems || itemsCount < maxItems);
    const enableLibrary = type.indexOf("db.") === -1;
    return (
        <div
            className={cn("grid file-grid fill", {
                "file-grid--dense": variant === "dense",
            })}
        >
            <List {...props} Iterator={MediaIterator} />
            {canAddMore ? (
                <UploadButton
                    onUpload={onUpload}
                    trigger={
                        <div
                            className={
                                "media-upload-placeholder rows rows-center rows-middle"
                            }
                        >
                            <div>
                                <IconButton icon={"mui-cloud_upload"} />
                            </div>
                            {variant !== "dense" ? <div>Upload</div> : null}
                        </div>
                    }
                />
            ) : null}
            {canAddMore && enableLibrary ? (
                <div
                    onClick={onOpenLibrary}
                    className={
                        "media-upload-placeholder rows rows-center rows-middle"
                    }
                >
                    <div>
                        <IconButton icon={"mui-search"} />
                    </div>
                    {variant !== "dense" ? <div>Library</div> : null}
                </div>
            ) : null}
        </div>
    );
}

class MediaCollection extends React.PureComponent {
    click = (id) => {
        const { pushQuery, mediaType } = this.props;
        pushQuery({ preview: [mediaType, id].join("/") });
    };

    upload = (files) => {
        const { onUpload, mediaType, onSubmit, entityType, entityId, id } =
            this.props;
        const path = [entityType, entityId, id];
        const callback = () => {
            if (onSubmit) onSubmit();
        };
        files.map((file) => onUpload(callback, mediaType, path, file));
    };

    open = () => {
        const { path, onOpenLibrary } = this.props;
        onOpenLibrary(path);
    };

    render() {
        return (
            <CollectionContainer
                {...this.props}
                onClick={this.click}
                onOpenLibrary={this.open}
                onUpload={this.upload}
            >
                {MediaGrid}
            </CollectionContainer>
        );
    }
}

export default withApiContext()(
    connect(mapStateToProps, mapDispatchToProps)(MediaCollection)
);
