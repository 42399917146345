import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import LibContentEditable from "react-contenteditable";
import { sanitize } from "dompurify";

const arePropsEqual = (prevProps, nextProps) => {
    if (nextProps.className !== prevProps.className) return false;
    if (nextProps.isFocused !== prevProps.isFocused) return false;
    if (nextProps.html !== prevProps.html) return false;
    if (nextProps["data-placeholder"] !== prevProps["data-placeholder"])
        return false;
    return true;
};

export const ContentEditableWatch = React.memo(
    (props) => <LibContentEditable {...props} />,
    arePropsEqual
);

const focusedStyle = {
    minWidth: "4em",
    textTransform: "none",
};

const ContentEditable = (props) => {
    const [isFocused, setIsFocused] = useState(false);

    const [value, setValue] = useState();

    const prevProps = useRef();

    const {
        commitOnBlur,
        onChange,
        onFocus,
        onBlur,
        onBackspaceKeyPress,
        onEnterKeyPress,
        tagName,
        label,
        className,
        disabled,
    } = props;

    useEffect(() => {
        if (props.value !== prevProps?.current?.value) {
            if (!commitOnBlur || !isFocused) {
                if (prevProps?.current?.value !== props.value) {
                    setValue(props.value);
                    prevProps.current = {
                        value: props.value,
                    };
                }
            }
        }
    }, [commitOnBlur, isFocused, props.value, value]);

    const change = (nextValue) => {
        if (commitOnBlur) {
            setValue(nextValue);
        } else if (!!onChange) {
            onChange(nextValue, true);
        }
    };

    const focus = () => {
        setIsFocused(true);
        if (onFocus) onFocus();
    };

    /**
     * Slightly defer blur execution in case it is
     * triggered by another event and component will be removed
     */
    const blur = (event) => {
        setTimeout(() => {
            if (commitOnBlur && value !== props.value) {
                onChange(value);
            }
            setIsFocused(false);
            if (onBlur) onBlur(event);
        }, 100);
    };

    const keypress = (e) => {
        let code = e.keyCode || e.which;

        let trimmedValue = value?.trim ? value.trim() : null;
        let hasValue = !!trimmedValue;

        if (hasValue && onEnterKeyPress && code === 13) {
            onEnterKeyPress();
            blur();
            e.preventDefault();
        }
        if (!hasValue && onBackspaceKeyPress && code === 8) {
            onBackspaceKeyPress();
            e.preventDefault();
        }

        if (code === 27) {
            if (!hasValue && onBackspaceKeyPress) {
                onBackspaceKeyPress();
                e.preventDefault();
            } else {
                blur();
            }
        }
    };

    const paste = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData("Text");
        change(e.target.innerHTML + pastedData);
    };

    const sanitizedValue = sanitize(value, { ALLOWED_TAGS: [] });

    return (
        <ContentEditableWatch
            onKeyDown={keypress}
            onKeyPress={() => null}
            onFocus={focus}
            onBlur={blur}
            onPaste={paste}
            onChange={(e) => {
                change(e.target.value);
            }}
            className={cn(className, {
                "inline-text-edit": true,
                focused: isFocused,
            })}
            style={isFocused ? focusedStyle : null}
            tagName={tagName}
            html={sanitizedValue}
            isFocused={isFocused ? "true" : "false"}
            data-s={props["data-s"]}
            data-placeholder={label}
            disabled={disabled}
            initialValue={sanitizedValue}
        />
    );
};

ContentEditable.propTypes = {
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    commitOnBlur: PropTypes.bool,
    onChange: PropTypes.func,
    onBackspaceKeyPress: PropTypes.func,
    onEnterKeyPress: PropTypes.func,
    tagName: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

ContentEditable.defaultProps = {
    tagName: "div",
};

export default ContentEditable;
