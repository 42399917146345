import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import MaterialPopover from "@material-ui/core/Popover";
import Drawer from "@material-ui/core/Drawer";
import useScreenSize from "util/hooks/useScreenSize";

export function ResponsivePopover(props) {
    const { isMobile } = useScreenSize();

    const { open, onClose, anchorEl, anchorOrigin, Component, ...other } = props;

    return isMobile ? (
        <Drawer anchor={"bottom"} open={!!open} onClose={onClose}>
            {props.children || <div />}
        </Drawer>
    ) : (
        <Component
            onClose={onClose}
            open={!!open}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            {...other}
        >
            {props.children || <div />}
        </Component>
    );
}

ResponsivePopover.defaultProps = {
    Component: MaterialPopover,
};

/**
 * Popover that switches to Drawer on mobile
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Popover(props) {

    const { trigger, content, onClose, anchorOrigin, ...other } = props;
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchor] = useState(null);

    const handleClose = useCallback((e) => {
        if (e) e.stopPropagation();
        setOpen(false);
        if (onClose) onClose();
    }, [setOpen, onClose]);

    const handleOpen = useCallback(
        (evt) => {
            if (evt) {
                evt.stopPropagation();
                evt.preventDefault();
                setAnchor(evt.currentTarget);
            }
            setOpen(true);
        },
        [setOpen]
    );

    return (
        <React.Fragment>
            {trigger ? trigger({ onClick: handleOpen }) : null}
            <ResponsivePopover
                open={!!open}
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                onClose={handleClose}
                {...other}
            >
                {content ? content({ onClose: handleClose }) : props.children}
            </ResponsivePopover>
        </React.Fragment>
    );
}

Popover.propTypes = {
    trigger: PropTypes.func,
    content: PropTypes.func,
    onClose: PropTypes.func,
};
