import React, { useEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { sortingMonitor } from "components/dnd/sortingMonitor";
//import PropTypes from 'prop-types';
import { NEW_ITEM_ID } from "components/dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

export function Draggable(props) {
    const {
        id,
        type,
        origin,
        onSort,
        onSortEnd,
        disableSort,
        render,
        refId,
        ...other
    } = props;
    const ref = useRef(null);
    const item = { id, type, origin };
    if (origin) {
        item.id = NEW_ITEM_ID;
        item.refId = id;
    }
    const [result, drag, preview] = useDrag({
        item,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: onSortEnd,
    });
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);
    const [, drop] = useDrop({
        accept: type,
        hover(hoverItem, monitor) {
            if (!onSort) return null;
            if (!ref.current) return null;
            const result = sortingMonitor(item, monitor, ref.current);
            if (!result) return;
            const { drag, hover } = result;
            onSort(drag, hover);
        },
        drop: onSortEnd,
    });
    if (disableSort) {
        drop(ref);
    } else {
        drag(drop(ref));
    }
    const DecoratedComponent = render;
    const passProps = { id, type, ...result, ...other };
    if (id === NEW_ITEM_ID && refId) passProps.id = refId;
    return <DecoratedComponent ref={ref} {...passProps} />;
}

Draggable.propTypes = {};
Draggable.defaultProps = {};
