export const loadWebfont = (source, family) => {
    return {
        type: "THEME.LOAD_WEBFONT",
        payload: {
            family,
            source
        }
    }
};

export const applyPreset = (preset, target, loadFonts, dark) => {
    return {
        type: "THEME.APPLY_PRESET",
        payload: {
            id: preset,
            target,
            loadFonts,
            dark
        }
    }
};

export const compileStyles = () => {
    return {
        type: "THEME.COMPILE_STYLES",
        payload: {}
    }
};

export const importColorScheme = (from, to, typeTo) => {
    return {
        type: "THEME.IMPORT_COLOR_SCHEME",
        payload: { from, to, typeTo }
    }
};

export const importTypography = (from, to, typeTo) => {
    return {
        type: "THEME.IMPORT_TYPOGRAPHY",
        payload: { from, to, typeTo }
    }
};