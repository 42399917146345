import { get } from "lodash";
import { NS_VALIDATION } from "state/sagas/validation";

function getValidation(store, type, id, path = []) {
    return get(store.changes, [NS_VALIDATION, [type, id].join("/"), ...path]);
}

export function isValidField(store, type, id, field) {
    return getValidation(store, type, id, ["fields", field]);
}

export function isValidForm(store, type, id) {
    return getValidation(store, type, id, ["errors"]) === 0;
}

export function selectFormError(store, type, id) {
    return getValidation(store, type, id, ["error"]);
}
