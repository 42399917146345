import { useApiActions } from "state/hooks/useApiActions";
import { fetch, fetchOne } from "state/actions/api";
// import {useActions} from "state/hooks/useActions";
// import {change, resetList} from "state/actions/data";
import { useContext, useEffect } from "react";
import { usePending } from "state/hooks/usePending";
import { useData } from "state/hooks/useData";
import { FILTER_NS } from "config/list-aliases";
import ApiContext from "containers/context/ApiContext";

export default function useFetch(props) {
    const { type, id, url, list, filter, once, enable } = props;

    const { onFetchList, onFetchOne } = useApiActions({
        onFetchList: fetch,
        onFetchOne: fetchOne,
    });

    const apiContext = useContext(ApiContext);

    // const {onResetList, onChange} = useActions({
    //     onResetList: resetList,
    //     onChange: change
    // });

    let fetchId = props.fetchId;
    if (!fetchId) fetchId = list ? [type, list].join("/") : id;

    const pending = usePending(fetchId, true);

    const shouldFetch = !once || pending !== false;
    const filterData = useData({ type: FILTER_NS, id: filter });

    useEffect(() => {
        if (enable !== false && shouldFetch) {
            if (list) {
                onFetchList(type, list, url, null, filter, null, fetchId);
            } else {
                onFetchOne(type, id, url, null, fetchId);
            }
        }
    }, [
        filterData,
        fetchId,
        shouldFetch,
        enable,
        type,
        list,
        id,
        filter,
        url,
        onFetchList,
        onFetchOne,
        apiContext,
        apiContext.lang,
    ]);

    return pending;
}
