import {useEffect, useMemo} from 'react';
import {useActions} from "state/hooks";
import {registerListProps} from "state/actions/list";
//import PropTypes from 'prop-types';

export function useRegisterList({type, list, filter, url}) {
    const {onRegisterProps} = useActions({
        onRegisterProps: registerListProps
    });

    const fetchProps = useMemo(() => ({
        type,
        list: list || type,
        filter: filter || list || type,
        url
    }), [type, list, filter, url]);

    useEffect(() => {
        onRegisterProps(fetchProps);
    }, [onRegisterProps, fetchProps])
}