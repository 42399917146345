import {selectEntity, selectFieldValue} from "state/selectors/data";
import {invert} from 'lodash';

export const NS_SCHEMA = 'schema.db';
export const NS_SCHEMA_FIELDS = 'db.fields';
export const NS_SCHEMA_TYPES = 'db.types';

export const DB_SYSTEM_TYPES = [
    'db.types',
    'db.filters',
    'db.list-views',
    'db.form-views',
    'db.enum-definitions',
    'db.fields'
];

export const findSchema = (store, type, id) => {
    const entity = selectEntity(store, type, id);
    const entityType = entity.element_type || entity.type;
    const schema = Object.values(store.data[NS_SCHEMA] || {});
    const typeId = type.split('.')[1];
    const formSchema = schema.filter(item => {
        return item.slug === typeId && !item.subtype_of;
    });
    const schemaId = formSchema.length ? formSchema[0].id : null;
    if (schemaId && entityType) {
        const typeSchema = schema.filter(item => {
            return item.subtype_of === schemaId && item.slug === entityType
        });
        if (typeSchema.length) return typeSchema[0].id;
    }
    return schemaId;
};

export function selectFields(store, type, id, schemaProps, selectFields) {
    let schemaId = findSchema(store, type, id);
    let index = selectFieldValue(store,
        'schema.db',
        schemaId,
        'field_index'
    );
    let fields = selectFieldValue(
        store,
        'schema.db',
        schemaId,
        'fields'
    );
    if (selectFields) {
        if (!index) return null;
        let revIndex = invert(index);
        let exclude = Object.values(selectFields).indexOf(false) > -1;
        if (exclude) {
            fields = fields.filter(fieldId => {
                let fieldName = revIndex[fieldId];
                if (selectFields[fieldName]) return true;
                return exclude && selectFields[fieldName] !== false;
            });
        } else {
            fields = Object.keys(selectFields).map(fieldName => index[fieldName]);
        }
    }
    return fields;
}