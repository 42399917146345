import React from 'react';
//import PropTypes from 'prop-types';
//import {Grid as VirtualizedList, AutoSizer} from "react-virtualized";

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VirtualizedList from 'react-virtualized/dist/commonjs/Grid';

export function VirtualizedListIterator(ItemRenderer) {
    return function({items, width, height, ...other}) {
        const render = (cell) => {
            let index = cell.rowIndex;
            let item = items[index];
            return <ItemRenderer
                {...other}
                key={item}
                index={index}
                id={item}
                style={cell.style}
            />;
        };
        if (width && height) return <VirtualizedList
            width={width}
            height={height}
            columnCount={1}
            columnWidth={width}
            rowHeight={63}
            overscanRowCount={0}
            rowCount={items.length}
            cellRenderer={render}
        />;

        return <AutoSizer>{({height, width}) => <VirtualizedList
            width={width}
            height={height}
            columnCount={1}
            columnWidth={width}
            rowHeight={63}
            overscanRowCount={0}
            rowCount={items.length}
            cellRenderer={render}
        />}</AutoSizer>;
    }
};