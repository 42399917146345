import React, { useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Fetch from "containers/Fetch";
import List from "containers/List";
import SelectLayout from "components/fields/select/SelectLayout";
import { ArrayIterator } from "components/list/iterator";
import { withData } from "containers/Entity";
import { withSchema } from "containers/Field";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "components/Avatar";
import {
    selectAliasedEntity,
    selectEntity,
    selectFieldValue,
} from "state/selectors/data";

import "css/fields/select.css";
import FilterField from "containers/FilterField";
import { takeProps } from "util/takeProps";
import { serializeQueryParams } from "util/uri";
import { SortableIterator } from "components/dnd";
import { selectRouteParam } from "state/selectors/router";

function OptionLabel(props) {
    return (
        props.name ||
        props.label ||
        props.title ||
        props.description ||
        props.type ||
        props.id ||
        null
    );
}

const avatar = {
    "db.identities": true,
};

class SelectListItem extends React.PureComponent {
    click = () => {
        const { onClick, id, value } = this.props;
        if (!onClick) return;
        onClick(value || id);
    };
    render() {
        const { selectValue, value, type, id, multiple, media } = this.props;

        const usedId = value || id;
        const isSelected = multiple
            ? selectValue && selectValue.indexOf(usedId) > -1
            : selectValue === usedId;
        return (
            <ListItem selected={isSelected} button onClick={this.click}>
                {avatar[type] ? (
                    <ListItemAvatar>
                        <Avatar media={media} />
                    </ListItemAvatar>
                ) : null}
                <ListItemText>
                    <OptionLabel {...this.props} type={this.props.data.type} />
                </ListItemText>
            </ListItem>
        );
    }
}

SelectListItem.autoFillProps = [
    "id",
    "value",
    "label",
    "name",
    "title",
    "description",
    "media",
];

function SelectedValue({ data }) {
    return (
        <div>
            <OptionLabel {...data} />
        </div>
    );
}

const Chip = React.forwardRef((props, ref) => {
    const { onClick, ...other } = props;
    const { id } = props;
    const click = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        onClick(id);
    };
    return (
        <div ref={ref} className={"select-chip"}>
            <div className={"chip-label"}>
                <SelectedValueWithData {...other} />
            </div>
            <div className={"chip-delete"} onClick={click}>
                <i className={"fh fh-close"} />
            </div>
        </div>
    );
});

const SelectListItemWithData = withData(SelectListItem);
const SelectedValueWithData = withData(SelectedValue);
const MultipleSelectionIterator = SortableIterator(Chip);

function MultipleSelection(props) {
    const value = props.id;
    const { onChange, type } = props;
    const sortEnd = (value) => {
        onChange(value, true);
    };
    return (
        <div className={"cols select-tags"}>
            <List
                type={type}
                items={value}
                Iterator={MultipleSelectionIterator}
                onSortEnd={sortEnd}
                onClick={onChange}
            />
        </div>
    );
}

class EmptyPlaceholder extends React.PureComponent {
    render() {
        const { label } = this.props;
        return <div className={"value-placeholder"}>{label}</div>;
    }
}

export const SelectListItemIterator = ArrayIterator(SelectListItemWithData);

const schemaSources = {
    "sections.types": "types.sections",
    "entries.types": "types.entries",
    "blocks.types.component.types": "types.components",
    "enum-definitions.types": "types.enum-definitions",
    "pages.types": "types.pages",
    "fields.types": "types.fields",
    "services.types": "types.services",
    "integrations.types": "types.integrations",
};

const enumSource = (store, ns, type, url) => {
    const [enumType, alias] = type.split("/");
    const enumData = selectAliasedEntity(store, enumType, alias);
    return {
        url,
        type: [ns, "enum-values"].join("."),
        items: enumData.values,
    };
};

const apiSource = (store, props) => {
    let srv = selectRouteParam(store, "service");
    let defaultNs = srv === "_" ? "console" : null;
    let [entityNs] = props?.entityType?.split(".") || [];
    if (!defaultNs && entityNs) defaultNs = entityNs;

    const source = props.source;
    let listId = source;

    let apps = ["cms", "db", "console", "apartments"];
    let query = source.split(":")[1];

    let app = query.split(".")[0];
    if (apps.indexOf(app) === -1) query = [defaultNs, query].join(".");
    let [type, queryParams] = query.split("?");
    let url = type.replace(".", "/");
    let urlParts = [url];
    if (queryParams) urlParts.push(queryParams);
    url = urlParts.join("?");

    if (type.indexOf("db.enum-definitions/") === 0) {
        return enumSource(store, "db", type, url);
    }

    if (type.indexOf("cms.enum-definitions/") === 0) {
        return enumSource(store, "cms", type, url);
    }

    if (type.indexOf("enum-definitions/") === 0) {
        return enumSource(store, "cms", type, url);
    }

    return {
        type,
        url: url,
        list: [listId].join("."),
    };
};

export const queryProps = [
    "id",
    "query_type",
    "repository",
    "tags",
    "integration",
    "enum_definition",
];

const querySource = (store, props) => {
    const listId = ["selectOptions", props.id].join(".");
    const list = ["cms.items", listId].join("/");
    const data = selectEntity(store, props.entityType, props.entityId);
    const params = takeProps(queryProps, data);
    params.id = props.id;
    params.list = list;
    return {
        type: "cms.items",
        url: "cms/query?" + serializeQueryParams(params),
        list: listId,
    };
};

const prefixValues = (value, prefix) => {
    if (!value || !prefix) return value;
    if (!Array.isArray(value)) return `${prefix}.${value}`;
    return value.map((v) => `${prefix}.${v}`);
};

const unprefixValues = (value, prefix) => {
    if (!prefix || !value) return value;
    if (!Array.isArray(value)) {
        if (typeof value !== "string") return value;
        return value.replace(prefix + ".", "");
    }
    return value.map((v) => v.replace(prefix + ".", ""));
};

const schemaDataSource = (store, props) => {
    const source = props.source;
    let query = source.split(":")[1];
    const dataId = schemaSources[query] || query;
    return {
        type: "schema.data.value",
        items: selectFieldValue(store, "schema.data", dataId, "values"),
        value: prefixValues(props.value, dataId),
        namespace: dataId,
    };
};

const mapDataSource = (store, props) => {
    const entity = selectEntity(store, props.entityType, props.entityId);
    const sourceKeyValue = entity[props.sourceKey];
    const source = props.sourceMap[sourceKeyValue];
    return parseSourceFetchProps(store, {
        ...props,
        sourceMap: null,
        sourceKey: null,
        source,
    });
};

const parseSourceFetchProps = function (store, props) {
    const source = props.source;
    if (!source) return {};
    let p = source.split(":")[0];
    if (props.sourceMap && props.sourceKey) {
        return mapDataSource(store, props);
    }
    if (p === "schema") return schemaDataSource(store, props);
    if (p === "api") return apiSource(store, props);
    if (p === "query") return querySource(store, props);
    return {};
};

function resolveFetchProps(store, props) {
    if (props.fetchProps) return props.fetchProps;
    return parseSourceFetchProps(store, props);
}

export function SelectList(props) {
    const { onClick, onClose, ...other } = props;
    const { multiple } = props;
    const handleChange = useCallback(
        (...args) => {
            onClick(...args);
            if (!multiple) onClose();
        },
        [onClick, onClose, multiple]
    );
    return <List {...other} onClick={handleChange} />;
}

class Select extends React.PureComponent {
    render() {
        const {
            id,
            label,
            value,
            onChange,
            variant,
            multiple,
            nullable,
            onClear,
            defaultFilterValue,
            error,
            helperText,
            ValueComponent,
        } = this.props;

        const { type, list, items, url } = this.props;

        let listId = list;

        let hasValue = multiple ? !!value && value.length > 0 : !!value;

        let DefaultValueRenderer = multiple
            ? MultipleSelection
            : SelectedValueWithData;

        const ValueRenderer = this.props.ValueRenderer || DefaultValueRenderer;

        return (
            <>
                {url ? (
                    <Fetch url={url} type={type} list={list} yieldData={true} />
                ) : null}
                <SelectLayout
                    id={id}
                    label={label}
                    hasValue={hasValue}
                    onClear={onClear}
                    nullable={nullable}
                    error={error}
                    helperText={helperText}
                    value={
                        hasValue ? (
                            <ValueRenderer
                                type={type}
                                id={value}
                                onChange={onChange}
                                nullable={nullable}
                                onClear={onClear}
                            />
                        ) : (
                            <EmptyPlaceholder label={label} />
                        )
                    }
                    ValueComponent={ValueComponent}
                    variant={variant}
                    selectMenu={(onClose) => (
                        <>
                            <div className={"pad-xs"}>
                                <FilterField
                                    defaultValue={defaultFilterValue}
                                    variant={"search"}
                                    type={type}
                                    list={listId}
                                    id={"search"}
                                />
                            </div>
                            <div className={"select-options"}>
                                <SelectList
                                    type={type}
                                    list={!items ? list : null}
                                    items={items}
                                    onClick={onChange}
                                    onClose={onClose}
                                    Iterator={SelectListItemIterator}
                                />
                            </div>
                        </>
                    )}
                />
            </>
        );
    }
}

const mapState = (store, props) => {
    return {
        ...props,
        ...resolveFetchProps(store, props),
    };
};

const mapDispatch = {};

export function toggleValue(value, id) {
    let nextValue = value ? value.slice() : [];
    if (nextValue.indexOf(id) > -1) {
        return nextValue.filter((v) => v !== id);
    }
    nextValue.push(id);
    return nextValue;
}

class SelectContainer extends React.PureComponent {
    change = (id, replace) => {
        const { namespace, value, multiple, onChange } = this.props;
        const idValue = unprefixValues(id, namespace);
        if (replace || !multiple) return onChange(idValue);
        onChange(
            toggleValue(unprefixValues(value, namespace), idValue),
            idValue
        );
    };

    clear = (evt) => {
        const { multiple, onChange } = this.props;
        evt.stopPropagation();
        onChange(multiple ? [] : null);
    };

    render() {
        return (
            <Select
                {...this.props}
                onChange={this.change}
                onClear={this.clear}
            />
        );
    }
}

const ConnectedSelectContainer = connect(
    mapState,
    mapDispatch
)(SelectContainer);

export default withSchema()(ConnectedSelectContainer);

Select.propTypes = {
    label: PropTypes.string,
    fetchProps: PropTypes.object.isRequired,
};

Select.defaultProps = {
    fetchProps: {},
};
