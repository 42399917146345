import get from "lodash/get";
import createCachedSelector from "re-reselect";
import { selectList as selectListIds } from "state/selectors/lists";
import { deepMerge } from "state/util";
import { getIn } from "lodash-redux-immutability";

const nullObj = {};

/**
 * Normalize 2 formats [type, id] and ["type/id", null]
 *
 * @param typeOrPath
 * @param id
 * @returns {[*, *]}
 */
export const normalizePath = (typeOrPath, id) => {
    if (!id && !typeOrPath) return [];
    let path = [typeOrPath, id];
    if (!id) path = typeOrPath.split("/");
    return path;
};

const selectEntityLayer = (store, type, id) => {
    if (!type || !id) return nullObj;
    return selectEntityPath(store, normalizePath(type, id));
};

const selectEntityPath = (store, path) => {
    return get(store, path) || nullObj;
};

export const resolveAlias = (store, type, alias) => {
    return get(store.data, [type, alias]) || null;
};

export const selectAliasedEntity = (store, type, alias) => {
    const resolved = resolveAlias(store, type, alias);
    if (!resolved) return nullObj;
    if (typeof resolved === "string") {
        return selectEntity(store, type, resolved);
    }
    return selectEntity(store, type, resolved?.id);
};

/**
 * Merge server and client data layers for a given entity
 * @params store, type, id
 */
const selectEntityCached = createCachedSelector(
    [
        (store, type, id) => selectEntityLayer(store.data, type, id),
        (store, type, id) => selectEntityLayer(store.changes, type, id),
    ],
    (data, changes) => {
        return deepMerge({}, data, changes);
    }
)((store, type, id) => normalizePath(type, id).join("/"));

/**
 * @params store, type, id
 */
export const selectEntity = selectEntityCached;

export const selectEntityData = createCachedSelector(
    [(store, type, id) => selectEntityLayer(store.data, type, id)],
    (data) => {
        return data || nullObj;
    }
)((store, type, id) => normalizePath(type, id).join("/"));

export const selectList = createCachedSelector(
    (store, type, list) =>
        selectListIds(store, type, list).map((id) =>
            selectEntity(store, type, id)
        ),
    (entities) => entities
)((store, type, list) => {
    return [type, list].join("/");
});

export const selectEntities = createCachedSelector(
    (store, type, items = []) =>
        items.map((id) => selectEntity(store, type, id)),
    (entities) => entities
)((store, type, items = []) => {
    return [type, items.join("|")].join("/");
});

export const selectFilteredItems = createCachedSelector(
    (store, type, ids, filter) => {
        return ids.map((id) => selectEntity(store, type, id)).filter(filter);
    },
    (entities) => entities
)((store, type, ids) => {
    return [type, ids.join("/")].join("/");
});

export const selectById = createCachedSelector(
    (store, type, ids) => {
        return ids.map((id) => selectEntity(store, type, id));
    },
    (entities) => entities
)((store, type, ids) => {
    return [type, ids.join("/")].join("/");
});

export const selectDataById = createCachedSelector(
    (store, type, ids) => {
        return ids.map((id) => selectEntityData(store, type, id));
    },
    (entities) => entities
)((store, type, ids) => {
    return [type, ids.join("/")].join("/");
});

/**
 * Select array of identifiers of changed entities by type
 *
 * @param store
 * @param type
 * @returns {string[]}
 */
export const selectChanges = (store, type) => {
    const changes = get(store.changes, [type]) || nullObj;
    return Object.keys(changes);
};

export const selectChangedEntities = (store, type) => {
    const ids = selectChanges(store, type);
    return ids.map((id) => selectEntity(store, type, id));
};

export const selectEntityChanges = (store, type, id) => {
    return get(store.changes, [type, id]) || nullObj;
};

/**
 * Select diff between current state and changes
 */
export const selectEntityChangeDiff = (store, type, id) => {
    const changes = selectEntityChanges(store, type, id);
    return selectEntityDiff(store, type, id, changes);
};

/**
 * Select diff between current state and provided data
 **/
export const selectEntityDiff = (store, type, id, changes) => {
    const data = selectEntityData(store, type, id);

    const keys = Object.keys(changes);
    const result = {};
    keys.forEach((key) => {
        if (data[key] !== changes[key]) {
            result[key] = {
                current: data[key],
                next: changes[key],
            };
        }
    });
    return result;
};

export const selectAllChanges = (store, ignoreTypes) => {
    const changes = store.changes;
    let types = Object.keys(changes);
    if (ignoreTypes)
        types = types.filter((type) => ignoreTypes.indexOf(type) === -1);
    const result = [];
    types.forEach((type) => {
        let ids = Object.keys(changes[type]);
        ids.forEach((id) => {
            result.push([type, id].join("/"));
        });
    });
    return result;
};

export const selectListChanges = (store, type, list) => {
    const ids = selectListIds(store, type, list);
    const changes = selectChanges(store, type);
    const listIds = ids.filter((id) => changes.indexOf(id) > -1);
    return listIds.map((id) => selectEntity(store, type, id));
};

export const selectFieldValue = (store, type, id, field) => {
    let entity = selectEntity(store, type, id);
    if (field.indexOf(".") > -1) return getIn(entity, field.split("."));
    return entity[field];
};

export const selectHasChanges = (store, type, id) => {
    let changes = selectEntityChanges(store, type, id);
    return Object.keys(changes).length;
};

export const selectIsDraft = (store, type, id) => {
    return !get(store.data, [type, id]);
};

export const selectAll = createCachedSelector(
    [(store, type) => get(store.data, [type]) || nullObj],
    (data) => Object.values(data)
)((store, type) => type);
