import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { useDrop } from "react-dnd";
import cn from "classnames";
import { Draggable } from "./Draggable";
import reorder from "util/reorder";
//import PropTypes from 'prop-types';

import { NEW_ITEM_ID } from "components/dnd";

function reducer(state, { type, items, drag, hover }) {
    switch (type) {
        case "reset":
            return { ...state, newId: null, items: state.currentItems };
        case "update":
            return { items, newId: null, currentItems: items };
        case "reorder":
            if (!drag || !hover) return state;
            let updatedOrder = reorder(state.items, drag.id, hover.id, true);
            return { ...state, items: updatedOrder };
        case "accept":
            if (state.items.indexOf(drag.id) > -1) return state;
            let updatedList = state.items.slice();
            updatedList.push(drag.id);
            return {
                ...state,
                newId: drag.id,
                refId: drag.refId,
                items: updatedList,
                origin: drag.origin,
            };
        default:
            throw new Error();
    }
}

export function Sortable(props) {
    const {
        items,
        onSortEnd,
        onAddToList,
        dropPlaceholder,
        type,
        className,
        children,
        ...other
    } = props;

    const [state, dispatch] = useReducer(reducer, {
        items,
        currentItems: items,
    });

    useEffect(() => {
        if (items !== state.currentItems) {
            dispatch({ type: "update", items });
        }
    });

    const handleSort = useCallback(
        (drag, hover) => {
            if (onSortEnd) {
                dispatch({ type: "reorder", drag, hover });
            }
        },
        [onSortEnd]
    );

    const handleSortEnd = useCallback(() => {
        if (onSortEnd && !state.newId) {
            onSortEnd(state.items);
            dispatch({ type: "reset" });
        }
    }, [onSortEnd, state]);

    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: type,
        hover(hoverItem, monitor) {
            if (state.items.indexOf(hoverItem.id) === -1) {
                dispatch({ type: "accept", drag: hoverItem });
            }
        },
        drop: () => {
            if (state.newId) {
                onAddToList(state);
                dispatch({ type: "reset" });
            }
        },
    });

    if (onAddToList) drop(ref);

    let hasItems = state.items && state.items.length > 0;

    if (!hasItems && dropPlaceholder)
        return (
            <div className={"drop-target"} ref={ref}>
                {dropPlaceholder}
            </div>
        );

    return (
        <div className={cn("drop-target", className)} ref={ref}>
            {children}
            {state.items.map((item, index) => (
                <Draggable
                    onSort={handleSort}
                    onSortEnd={handleSortEnd}
                    type={type}
                    {...other}
                    key={item}
                    id={item}
                    index={index}
                    refId={item === NEW_ITEM_ID ? state.refId : item}
                    isDraggedInto={item === NEW_ITEM_ID}
                />
            ))}
        </div>
    );
}

Sortable.propTypes = {};
Sortable.defaultProps = {};
