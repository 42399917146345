import React from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';
import {withSchema} from "containers/Field";
import {FormFieldIterator} from 'containers/Form';
import List from "containers/List";
import Seo from "components/fields/seo/Seo";
import {collapse, expand} from "state/actions/tree";
import {selectIsExpanded} from "state/selectors/tree";

import 'css/fields/form-section.css';

const mapStateToProps = (store, props) => {
    const expandType = 'app.forms';
    const expandList = 'form-sections';
    const id = props.id;
    return {
        expand: selectIsExpanded(store, expandType, expandList, id),
        expandType,
        expandList
    }
};

const mapDispatchToProps = {
    onExpand: expand,
    onCollapse: collapse
};

const customTypes = {
    seo: Seo
};

class FormSection extends React.PureComponent {

    toggle = () => {
        const {
            expand,
            expandType,
            expandList,
            id,
            onExpand,
            onCollapse
        } = this.props;

        if (expand) {
            onCollapse(expandType, expandList, id);
        } else {
            onExpand(expandType, expandList, id);
        }
    }

    render() {
        const {
            label,
            schema,
            entityType,
            entityId,
            id,
            expand
        } = this.props;

        const { fields } = schema;

        if (customTypes[id]) {
            let Component = customTypes[id];
            return <div className={'grow'}>
                <Component {...this.props}>
                    <List
                        formEntityType={entityType}
                        formEntityId={entityId}
                        type={'db.fields'}
                        items={fields}
                        Iterator={FormFieldIterator} />
                </Component>
            </div>
        }

        if (!fields) return null;

        return (<div className={'grow form-section'}>
            <div
                className={cn('form-section-heading', {'form-section-heading--expand': expand})}
                onClick={this.toggle}>{label}</div>
            { expand ? <div className={'fields'}>
                <List
                    formEntityType={entityType}
                    formEntityId={entityId}
                    type={'db.fields'}
                    items={fields}
                    Iterator={FormFieldIterator} />
            </div> : null }
        </div>);
    }

}

export default withSchema()(connect(
    mapStateToProps,
    mapDispatchToProps
)(FormSection));