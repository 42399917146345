export const fetch =
    (context) =>
    (
        type,
        list,
        url,
        view = null,
        filter = null,
        append = null,
        fetchId = null,
        yieldData = null
    ) => {
        return {
            type: "API.FETCH",
            payload: {
                type,
                list,
                url,
                view,
                filter,
                append,
                fetchId,
                yieldData,
            },
            context,
        };
    };

export const fetchOne = (context) => (type, id, url, alias, fetchId) => {
    return {
        type: "API.FETCH_ONE",
        payload: {
            type,
            id,
            url,
            alias,
            fetchId,
        },
        context,
    };
};

export const submitOne =
    (context) => (type, id, url, alias, submitId, data) => {
        return {
            type: "API.SUBMIT_ONE",
            payload: {
                type,
                id,
                url,
                alias,
                submitId,
                data,
            },
            context,
        };
    };

export const post = (context) => (url, data, submitId, type) => ({
    type: "API.POST",
    payload: {
        url,
        data,
        submitId,
        type,
    },
    context,
});

export const put = (context) => (url, data, submitId, type) => ({
    type: "API.PUT",
    payload: {
        url,
        data,
        submitId,
        type,
    },
    context,
});

export const submitOneCallback =
    (context) =>
    (callback, ...args) => {
        const submitAction = submitOne(context)(...args);
        submitAction.callback = callback;
        return submitAction;
    };

export const submitList = (context) => (type, list, url) => {
    return {
        type: "API.SUBMIT_LIST",
        payload: {
            type,
            list,
            url,
        },
        context,
    };
};

export const remove = (context) => (type, id, url) => {
    return {
        type: "API.DELETE",
        payload: {
            type,
            id,
            url,
        },
        context,
    };
};

export const removeCallback =
    (context) =>
    (cb, ...args) => {
        const removeAction = remove(context)(...args);
        removeAction.callback = cb;
        return removeAction;
    };

export const submitBatch = (context) => (url, paths, submitId, reset) => {
    return {
        type: "API.SUBMIT_BATCH",
        payload: {
            url,
            paths,
            submitId,
            reset,
        },
        context,
    };
};

export const submitBatchCallback =
    (context) =>
    (callback, ...args) => {
        const submitAction = submitBatch(context)(...args);
        submitAction.callback = callback;
        return submitAction;
    };

export const submitOrder = (context) => (url, type, list, order) => {
    return {
        type: "API.SUBMIT_ORDER",
        payload: {
            url,
            type,
            list,
            order,
        },
        context,
    };
};

export const initOAuthFlow = (context) => (url) => ({
    type: "API.OAUTH.INIT",
    payload: { url },
    context,
});

export const apiException = (error) => ({
    type: "API.EXCEPTION",
    payload: { error },
});
