import React from 'react';

import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import IconButton from 'components/IconButton';
import Icon from '@material-ui/core/Icon';
import DebounceInput from 'react-debounce-input';
import {CircularProgress} from "@material-ui/core";

const searchInputClasses = {
    root: 'search-input'
};

const debounceInputProps = {
    minLength: 2,
    debounceTimeout: 600
};

const controlStyle = {width: '100%'};

export default class TextSearch extends React.PureComponent {

    handleChange = (evt) => {
        this.props.onChange(evt.target.value);
    };

    handleClear = () => {
        this.props.onChange('');
    };

    render() {
        const {
            value,
            label,
            pending,
            debounce,
            autoFocus
        } = this.props;

        return (<FormControl style={controlStyle}>
            <InputBase
                onChange={this.handleChange}
                value={value}
                placeholder={label}
                fullWidth={true}
                type="text"
                autoFocus={autoFocus}
                inputComponent={DebounceInput}
                inputProps={debounce ? debounceInputProps : {}}
                classes={searchInputClasses}
                startAdornment={pending
                    ? <div className={'search-input__icon'}>
                        <CircularProgress color={'inherit'} size={24} />
                    </div>
                    : <Icon className="search-input__icon">search</Icon>}
                endAdornment={value ? <IconButton
                    onClick={this.handleClear}
                    icon={'mui-clear'} /> : null}
            />
        </FormControl>);
    }

}

TextSearch.defaultProps = {
    debounce: true,
    autoFocus: true
}