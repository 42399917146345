const translatableKeys = [
    'title',
    'alt',
    'headline',
    'text',
    'link_label',
    'custom_label',
    'label',
    'nav_label',
    'slug',
    'link',
    'tooltip_text',
    'tooltip',
    'promo_code_placeholder',
    'promo_code_label',
    'promo_code_value',
    'subtitle', //in offers,
    'default_seo_title_format',
    'default_seo_title',
    'seo',
    'open_graph',
    'seo_title_default_template',
    'seo_title_template',
    'footer_signature',
    'booking_url',
    'content',
    'placeholder',
    'input_label'
];

const nested = {
    seo: ['title', 'description', 'keywords'],
    open_graph: ['title', 'description']
};

function embedTranslation(value, lang, key) {
    if (!value) return value;
    if (!(typeof value === 'object')) return value;
    if (nested[key]) return embedTranslations(value, lang, nested[key]);
    if (value.hasOwnProperty(lang)) return value[lang];
    return null;
}

function embedTranslations(item, lang, keys) {
    keys.forEach(key => {
        if (item[key]) {
            item[key] = embedTranslation(item[key], lang, key);
        }
    });
    return item;
}

function supportsTranslations(type) {
    return type.indexOf('cms.') === 0;
}

function intl(type, items, lang) {

    if (!supportsTranslations(type)) return items;

    const keys = Object.keys(items);
    keys.forEach(key => {
        let item = items[key];
        item = embedTranslations(item, lang, translatableKeys);
        items[key] = item;
    });
    return items;
}

export default intl;