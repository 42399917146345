import React from "react";
import PropTypes from "prop-types";
import MuiTextField from "@material-ui/core/TextField";
import TextSearch from "./TextSearch";
import TextDense from "components/fields/text/TextDense";

import "css/fields/text-field.css";
import ContentEditable from "components/ContentEditable";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./style.css";

const globalInputProps = {
    form: {
        autocomplete: "off",
    },
};

export default class TextField extends React.PureComponent {
    handleChange = (evt) => {
        this.props.onChange(evt.target.value, true);
    };

    render() {
        const {
            type,
            value,
            label,
            onChange,
            entityType,
            entityId,
            children,
            fieldType,
            variant,
            defaultValue,
            disabled,
            optional,
            InputProps,
            ...other
        } = this.props;

        /**
         * Enforce string value for mui TextField
         * @type {string}
         */
        let normalizedValue = value ? value : defaultValue || "";

        if (variant === "search")
            return (
                <TextSearch
                    fullWidth={true}
                    value={normalizedValue}
                    label={label}
                    onChange={onChange}
                    {...other}
                />
            );

        if (variant === "inline")
            return (
                <ContentEditable
                    className={other.className}
                    value={normalizedValue}
                    label={label}
                    onChange={onChange}
                    onBackspaceKeyPress={other.onBackspaceKeyPress}
                    onEnterKeyPress={other.onEnterKeyPress}
                    onFocus={other.onFocus}
                    onBlur={other.onBlur}
                    commitOnBlur={other.changeMode !== "change"}
                    disabled={disabled}
                />
            );

        if (variant === "dense")
            return <TextDense {...this.props} value={normalizedValue} />;

        let passInputProps = { ...globalInputProps, ...InputProps };

        if (optional) {
            passInputProps = {
                endAdornment: (
                    <InputAdornment position={"end"}>
                        <span className={"text-field-adornment--optional"}>
                            Optional
                        </span>
                    </InputAdornment>
                ),
                ...passInputProps,
            };
        }

        return (
            <MuiTextField
                variant={variant || "outlined"}
                fullWidth={true}
                type={type}
                value={normalizedValue}
                onChange={this.handleChange}
                label={label}
                autoComplete={"off"}
                disabled={disabled}
                {...other}
                InputProps={passInputProps}
            />
        );
    }
}

TextField.propTypes = {
    value: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

TextField.defaultProps = {
    value: "",
    type: "text",
    disabled: false,
    InputProps: {},
};
