import React from 'react';
import PropTypes from 'prop-types';
import Check from "components/fields/checkbox/Check";
import Switch from "components/fields/checkbox/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiCheckbox from '@material-ui/core/Checkbox';

export default class Checkbox extends React.PureComponent {

    toggle = () => {
        const { readOnly, onChange, value } = this.props;
        if (readOnly) return;
        onChange(!value);
    };

    render() {
        const { variant, ...other } = this.props;

        if (variant === 'check')
            return <Check {...other} onChange={this.toggle} />;

        if (variant === 'checkbox')
            return <FormControlLabel
                control={<MuiCheckbox
                    checked={!!other.value}
                    onChange={this.toggle}
                />}
                label={other.label} />;

        return (<Switch {...other} onChange={this.toggle} />);
    }

}

Checkbox.propTypes = {
    onChange: PropTypes.func,
    variant: PropTypes.oneOf(['check', 'default', 'dense', 'checkbox'])
};

Checkbox.defaultProps = {
    variant: 'default'
};